"use client"

import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import Link from 'next/link'
import {usePathname, useRouter} from 'next/navigation'
import {useAuth} from "@/app/db/Auth";
import {LogOutIcon, SettingsIcon, WalletIcon} from "lucide-react";
import {MobileSidebar} from "@/app/(client)/app/components/mobile-sidebar";

export default function AppHeader() {
    const {user,team} = useAuth()
    const nav = useRouter()

    return (
        <header className={"top-0 sticky z-10 dark:bg-background bg-background/90"}>
            <nav className="mx-auto flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex items-center gap-x-4">
                    <Link href="/" className="-m-1.5 mt-1.5 p-1.5 text-lg font-bold leading-6 text-gray-900 dark:text-white flex items-center gap-x-2">
                        <img src="/logo-full.svg" className={"h-8 dark:invert"} alt={"logo"}/>
                    </Link>
                    <div className={"hidden md:flex gap-x-8 font-semibold mt-1 text-md items-center ml-10"}>
                        <Linkable link={"/app"} text={"My Buckets"}/>
                        <Linkable link={"/app/keys"} text={"API Keys"}/>
                        <Linkable link={"/app/settings"} text={"Settings"}/>
                    </div>
                </div>
                <div className="flex md:hidden">
                    <MobileSidebar/>
                </div>
                <div className="hidden md:flex mt-1 gap-x-6">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild disabled={!user}>
                            <Avatar className={"rounded-full cursor-pointer hover:opacity-50 transition-all"}>
                            <AvatarImage src="https://upload.works/f/0c87495f-63ad-42fb-b1b7-14d15330124c/doggo.webp" alt="@shadcn" />
                            <AvatarFallback></AvatarFallback>
                            </Avatar>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                            <DropdownMenuLabel>
                                <div className={""}>
                                    <p className={"truncate"}>{team?.name ?? "Loading..."}</p>
                                    <p className={"opacity-50 text-xs font-normal truncate"}>{user?.email ?? "Loading..."}</p>
                                </div>
                            </DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={(e)=>{nav.push("/app/billing")}}>
                                <WalletIcon className={"mr-2 scale-75"}/>
                                <Link href="/app/billing">Billing</Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={(e)=>{nav.push("/app/settings")}}>
                                <SettingsIcon className={"mr-2 scale-75"}/>
                                <Link href="/app/settings">Settings</Link>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={(e)=>{nav.push("/logout")}}>
                                <LogOutIcon className={"mr-2 scale-75"}/>
                                <Link href="/logout">Logout</Link>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </nav>
        </header>
        )
}


// @ts-ignore
export const Linkable = ({ text, link, mobile = false }) => {
    let currentUrl = usePathname();
    let current = currentUrl.startsWith(link);

    if (currentUrl == "/app" && link == "/app") current=true
    else if (currentUrl.startsWith("/app/bucket") && link == "/app") current=true
    else if (link == "/app") current = false


    return (
        <>
            <Link href={link}>
                <div className={`${mobile && "py-2"}  ${current && " bg-emerald-300 rounded-lg dark:text-black"} text-center px-2 flex w-full dark:hover:fill-white dark:fill-black fill-white hover:fill-black items-center hover:underline transition-all`}>
                    <p className={""}>{text}</p>
                </div>
            </Link>
        </>
        );
}
