"use client"

import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import Link from "next/link";
import {Linkable} from "@/app/(client)/app/components/app-header";
import { useState } from "react";
import { Separator } from "@/components/ui/separator";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAuth } from "@/app/db/Auth";
import { useRouter } from "next/navigation";


export function MobileSidebar() {
    
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const {user,team} = useAuth()
    
    return (
        
        <>
        <Sheet open={mobileMenuOpen} onOpenChange={(e)=>setMobileMenuOpen(e)}>
            <button type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-white"
            onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            <svg className={"h-6 w-6 fill-black dark:fill-white"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
            </button>
            <SheetContent side={"right"}>
                <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
                    <Link href="/" className="mx-auto pb-10 -m-1.5 mt-1.5 p-1.5 text-lg font-bold leading-6 text-gray-900 dark:text-white flex items-center gap-x-2">
                        <img src="/logo-full.svg" className={"h-8 dark:invert"} alt={"logo"}/>
                    </Link>
                    <Linkable mobile={true} link={"/app"} text={"My Buckets"}/>
                    <Linkable mobile={true} link={"/app/keys"} text={"API Keys"}/>
                    <Separator/>
                    <Linkable mobile={true} link={"/app/billing"} text={"Billing"}/>
                    <Linkable mobile={true} link={"/app/settings"} text={"Settings"}/>
                    <Linkable mobile={true} link={"/logout"} text={"Logout"}/>
                    <Separator/>
                </nav>
                <div className={"mt-4 flex gap-x-2 hover:bg-accent cursor-pointer rounded-lg py-2 px-2"}>
                    <div className={"my-auto"}>
                        <Avatar className={"rounded-full"}>
                        <AvatarImage src="https://upload.works/f/0c87495f-63ad-42fb-b1b7-14d15330124c/doggo.webp" alt="@shadcn" />
                        <AvatarFallback></AvatarFallback>
                        </Avatar>
                    </div>
                    <div className={"flex-none"}>
                        <p className={"truncate"}>{team?.name ?? "Loading..."}</p>
                        <p className={"opacity-50 text-xs font-normal truncate"}>{user?.email ?? "Loading..."}</p>
                    </div>
                </div>
            </SheetContent>
        </Sheet>
        </>
    )
}